
html,
.logo {
  margin: 0  !important;
  padding: 0  !important;
  width: 100% !important;
  height: 100% !important;
  display: table !important;
  background-color: black !important;
}

.container {
    display: table-cell !important;
    text-align: center !important;
    vertical-align: middle !important;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.neon {
    font-family: neon;
    color: #FB4264;
    font-size: 9vw;
    line-height: 9vw;
    text-shadow: 0 0 3vw #e08f9e;
  }

.flux {
    font-family: neon !important;
    color: #426DFB !important;
    font-size: 9vw !important;
    line-height: 9vw !important;
    text-shadow: 0 0 3vw #2356FF !important;
}

.neon {
    animation: neon 1s ease infinite;
    -moz-animation: neon 1s ease infinite;
    -webkit-animation: neon 1s ease infinite;
  }

  @keyframes neon {
    0%,
    100% {
      text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #f92ce1, .5vw .5vw .1vw #80144c;
      color: #fe28f7;
    }
    50% {
      text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #eeede8;
      color: #e9e7e0;
    }
  }
  
  .flux {
    animation: flux 2s linear infinite;
    -moz-animation: flux 2s linear infinite;
    -webkit-animation: flux 2s linear infinite;
    -o-animation: flux 2s linear infinite;
  }
  
  @keyframes flux {
    0%,
    100% {
      text-shadow: 0 0 1vw #1041FF, 0 0 3vw #1041FF, 0 0 10vw #1041FF, 0 0 10vw #1041FF, 0 0 .4vw #8BFDFE, .5vw .5vw .1vw #147280;
      color: #28D7FE;
    }
    50% {
      text-shadow: 0 0 .5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180, 0 0 5vw #082180, 0 0 .2vw #082180, .5vw .5vw .1vw #0A3940;
      color: #146C80;
    }
  }

  @font-face {
    font-family: 'neon';
    src: url('/src/assets/fonts/neon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  #Terms_Condition > a{
    display: none;
  }
  #Terms_Condition {
    
  }




.menu{
  margin-top: 100px !important;
}
.button {
  color: #db3eb1;
  border: 6px solid #db3eb1;
  border-radius:15px;
  padding: 10px 20px;
  font-size: 15px;
  font-family: tahoma;
  letter-spacing:5px;
  cursor: pointer;
  font-weight: bold;
  filter: drop-shadow(0 0 15px #db3eb1) drop-shadow(0 0 50px #db3eb1) contrast(2) brightness(2);
  transition: .5s;
}

.button:hover {
  color: black;
  background-color: #db3eb1;
  filter: drop-shadow(0 0 20px #db3eb1) contrast(2) brightness(2);
}






/* 





/* WHATS UP BUTTON */
/* .float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}  */
